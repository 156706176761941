import React from 'react';
import { useTheme } from 'next-themes';
import Image from 'next/image';

export function Logo(props: React.HTMLProps<HTMLImageElement>) {
    const {theme} = useTheme()
    const isDark = theme === "dark"
    return <>
        <Image src={isDark ? "/globe.svg" : "/globe.svg"} alt="Logo" width={Number(props?.width) || 100} height={Number(props?.height) || 100} />
    </>
}