import {
    Sidebar,
    SidebarContent,
    SidebarFooter,
    SidebarGroup,
    SidebarHeader,
    SidebarMenu,
    SidebarMenuItem,
    SidebarMenuButton,
    useSidebar,
    SidebarGroupContent,
    SidebarGroupLabel,
} from "@/components/ui/sidebar"
import { Avatar, AvatarImage } from "@/components/ui/avatar"

import { SignOutButton, useClerk } from "@clerk/nextjs"
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator } from "@/components/ui/dropdown-menu"
import { LogOut, ChevronsUpDown, Settings,  Home, FolderSearch,  Bot, FilePlus } from "lucide-react"

import { useUser } from "@clerk/nextjs"
import { Skeleton } from "@/components/ui/skeleton"
import Link from "next/link"
import { useTranslation } from "react-i18next"
import { OppositeModeIcon } from "@/components/themes/mode-toggle"
import { useTheme } from "next-themes"
import { ScrollArea } from "@/components/ui/scroll-area"
import { to } from "@/routes"
import { usePathname } from "next/navigation"
import { Logo } from "@/components/logo"

export function AppSidebar() {
    const { setOpenMobile } = useSidebar()
    const closeMobileSidebar = () => {
        setOpenMobile(false)
    }
    const location = usePathname()
    const isActive = (path: string) => {
        return location.includes(path)
    }
    const { t } = useTranslation("navigation")
    return <>
        <Sidebar>
            <SidebarHeader>
                <Link onClick={closeMobileSidebar} href={to.home()} className="flex justify-center items-center mt-8 mb-5">
                    <Logo width={50} height={50} />
                </Link>
            </SidebarHeader>
            <ScrollArea className="h-full">
                <SidebarContent>
                    <SidebarGroup>
                        <SidebarGroupLabel>{t("dashboard")}</SidebarGroupLabel>
                        <SidebarGroupContent>
                            <SidebarMenu>
                                <SidebarMenuItem>
                                    <SidebarMenuButton asChild isActive={false}>
                                        <Link href={to.home()} onClick={closeMobileSidebar}>
                                            <Home /><div>{t("home")}</div>
                                        </Link>
                                    </SidebarMenuButton>
                                </SidebarMenuItem>
                            </SidebarMenu>
                        </SidebarGroupContent>
                    </SidebarGroup>

                    <SidebarGroup>
                        <SidebarGroupLabel>{t("knowledge")}</SidebarGroupLabel>
                        <SidebarGroupContent>
                            <SidebarMenu>
                                <SidebarMenuItem>
                                    <SidebarMenuButton asChild isActive={isActive(to.browseKnowledge())}>
                                        <Link href={to.browseKnowledge()} onClick={closeMobileSidebar}>
                                            <FolderSearch /><div>{t("browse")}</div>
                                        </Link>
                                    </SidebarMenuButton>
                                    <SidebarMenuButton asChild isActive={isActive(to.addSource())}>
                                        <Link href={to.addSource()} onClick={closeMobileSidebar}>
                                            <FilePlus /><div>{t("add")}</div>
                                        </Link>
                                    </SidebarMenuButton>
                                    <SidebarMenuButton asChild isActive={isActive(to.conversations())}>
                                        <Link href={to.conversations()} onClick={closeMobileSidebar}>
                                            <Bot /><div>{t("interact")}</div>
                                        </Link>
                                    </SidebarMenuButton>
                                </SidebarMenuItem>
                            </SidebarMenu>
                        </SidebarGroupContent>
                    </SidebarGroup>
                </SidebarContent>
            </ScrollArea>
            <SidebarFooter>
                <UserMenu />
            </SidebarFooter>
        </Sidebar>
    </>
}

function UserMenu() {
    const { user, isLoaded } = useUser()
    const { openUserProfile } = useClerk()
    const { imageUrl: userImg } = user || {}
    const { primaryEmailAddress } = user || {}
    const { setOpenMobile, isMobile } = useSidebar()
    const { t } = useTranslation()
    const { theme, setTheme } = useTheme()
    const handleToggleTheme = () => {
        setTheme(theme === "dark" ? "light" : "dark")
    }
    const handleOpenProfile = () => {
        openUserProfile()
        setOpenMobile(false)
    }
    if (!isLoaded) return <Skeleton className="h-10" />
    return <>
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <SidebarMenuButton className="h-fit flex items-center">
                    <Avatar className="size-9 rounded-md">
                        <AvatarImage src={userImg} />
                    </Avatar>
                    <div className="p-1">
                        <div className="font-semibold ">{user?.fullName}</div>
                        <div className="text-xs text-muted-foreground">{primaryEmailAddress?.emailAddress}</div>
                    </div>
                    <ChevronsUpDown className="ml-auto" />
                </SidebarMenuButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                align="end"
                side={isMobile ? "top" : "right"}
                className="w-[--radix-popper-anchor-width]"
            >
                <DropdownMenuItem className="cursor-pointer" onClick={handleToggleTheme}>
                    <OppositeModeIcon /><span>{theme === "dark" ? t("light-mode") : t("dark-mode")}</span>
                </DropdownMenuItem>
                <DropdownMenuItem className="cursor-pointer" onClick={handleOpenProfile}>
                    <Settings /><span>{t("settings")}</span>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <SignOutButton>
                    <DropdownMenuItem className="cursor-pointer">
                        <LogOut /><span>{t("logout")}</span>
                    </DropdownMenuItem>
                </SignOutButton>
            </DropdownMenuContent>
        </DropdownMenu>
    </>
}
