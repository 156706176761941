"use client"

// Icons
import { TriangleAlert } from "lucide-react"

// Components
import { Skeleton } from "@/components/ui/skeleton";

// Utils
import { cn } from "@/lib/utils";

// Fonts
import { Fredoka, Inter, Work_Sans } from "next/font/google";
import { GeistSans } from "geist/font/sans";
import { GeistMono } from "geist/font/mono";

// Hooks
import { useTranslation } from "react-i18next";

const fredoka = Fredoka({ subsets: ["latin"] });
const inter = Inter({ subsets: ["latin"] });
const workSans = Work_Sans({ subsets: ["latin"] });
export const Fonts = {
    fredoka: fredoka.className,
    inter: inter.className,
    workSans: workSans.className,
    geistSans: GeistSans.className,
    geistMono: GeistMono.className
};

interface ErrorProps {
    code?: string,
    payload?: any,
    detail?: string
    props?: any,
    children?: React.ReactNode,
    className?: string
}

export function TextError({ className, props, children, code = "", payload, detail }: ErrorProps) {
    const { t } = useTranslation("errors")
    return <div className={cn("flex gap-2 text-destructive", className)}  {...props}>
        <div><TriangleAlert className="text-2xl" /></div>{children ? children : code && code !== "" && t(code, payload) !== code ? t(code, payload) : detail ? detail : t("unknown")}
    </div>
}

interface TextProps extends React.HTMLAttributes<HTMLDivElement> {
    className?: string,
    mb?: boolean,
    description?: string,
    title?: string
    loading?: boolean
}
export function TitleLG({ children, className, ...props }: TextProps) {
    return <h2 className={cn("text-2xl font-bold", className)} {...props}>{children}</h2>
}

export function TitleSmall({ children, className, ...props }: TextProps) {
    return <h5 className={cn("text-lg font-medium", className)} {...props}>{children}</h5>
}

export function TitleMedium({ children, className, ...props }: TextProps) {
    return <h4 className={cn("text-xl font-bold", className)} {...props}>{children}</h4>
}

export function PageTitle({ children, title, className, description, mb=true, loading, ...props }: TextProps) {
    return <>
    <h1 className={cn("sm:text-3xl text-xl font-bold break-all", className, mb && !description && "mb-5")} {...props}>
        {!loading ? title : <Skeleton className="h-6 max-w-[500px]" />}
        {description && <Description className={cn(mb && "mb-5")}>{!loading? description: <Skeleton className="h-6 max-w-[800px] mt-2" />}</Description>}
        {children}
    </h1>
    </>
}

export function Description({ children, className, ...props }: TextProps) {
    return <div className={cn("text-base text-muted-foreground font-normal", className)} {...props}>{children}</div>
}

interface EmptyStateProps {
    className?: string,
    children?: React.ReactNode
    title?: string,
    description?: string
}
export function EmptyState({ className, children, title, description }: EmptyStateProps) {
    return <>
        <div className={cn("flex flex-col items-center gap23", className)}>
            <div className="text-muted-foreground text-lg">
                {title}
            </div>
            <p className="text-muted-foreground text-sm">
                {description}
            </p>
            {children}
        </div>
    </>
}

export const Text = {
    Error: TextError,
    TitleSmall,
    TitleMedium,
    TitleLG,
    Title: TitleLG,
    PageTitle,
    EmptyState,
    Description
}