"use client"

import { cn } from "@/lib/utils"
import { SidebarProvider, SidebarTrigger, useSidebar } from "@/components/ui/sidebar"
import { AppSidebar } from "@/components/navigation/app-sidebar"
import { Separator } from "@/components/ui/separator";
import { Breadcrumbs } from "@/components/navigation/breadcrumbs";
import { OrganizationSwitcher, UserButton } from "@clerk/nextjs";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useMediaQuery, screenSizes } from "@/hooks/useMediaQuery";
import { PageTitle } from "@/components/text";
import { Skeleton } from "@/components/ui/skeleton";
import { useTheme } from "next-themes";
import { dark } from '@clerk/themes'
import { to } from "@/routes";

interface LayoutProps extends React.HTMLProps<HTMLDivElement> {
    children: React.ReactNode;
    center?: boolean;
    className?: string;
}
export function FullLayout({ children, center, className, ...props }: LayoutProps) {

    return <>
        <div className={cn("max-w-screen overflow-x-hidden min-h-screen", center && "flex justify-center items-center", className)} {...props}>
            {children}
        </div>
    </>
}

export function DashboardLayout({ children }: LayoutProps) {
    return <>
        <SidebarProvider>
            <DashBoardLayoutWithProvider>{children}</DashBoardLayoutWithProvider>
        </SidebarProvider>
    </>
}

function DashBoardLayoutWithProvider({ children }: LayoutProps) {
    const { isMobile, open } = useSidebar()
    const { theme } = useTheme()
    const isDark = theme === "dark"
    return <>
        <AppSidebar />
        <ScrollArea className={cn("bg-sidebar w-full @container flex flex-col h-screen")}>
            <div className={cn(!isMobile && (!open ? "p-2" : "pr-2 py-2"), "flex flex-col h-full flex-1")}>
                <div className={cn("h-full w-full flex flex-col bg-background  md:min-h-[calc(100vh-16px)] min-h-screen", !isMobile && "rounded-md border")}>
                    <div className="flex h-14 justify-between p-2 items-center w-full  border-b md:border-b-transparent transition-all">
                        <div className="flex items-center gap-1.5 h-full">
                            <SidebarTrigger />
                            <Separator orientation="vertical" className="h-5 mr-1" />
                            <div className="@[650px]:block hidden">
                                <Breadcrumbs />
                            </div>
                        </div>
                        <div className="flex items-center gap-1.5 org-switcher md:pr-2">
                            <OrganizationSwitcher
                                appearance={{ baseTheme: isDark && dark || undefined }}
                                hidePersonal
                                afterSelectOrganizationUrl={to.home()}
                            />
                            <UserButton />
                        </div>
                    </div>
                    <div className="h-full flex-1 flex flex-col justify-start">
                        {children}
                    </div>
                </div>
            </div>
        </ScrollArea>
    </>
}

export function TwoColumnLayout({ children, className, breakpoint = "xl"}: { children: React.ReactNode, className?: string, breakpoint?: keyof typeof screenSizes }) {

    return <div className="@container ">
        <div className={cn("flex flex-col w-full gap-x-8 gap-y-4 @[1000px]:flex @[1000px]:flex-row ", className)}>
            {children}
        </div>
    </div>
}
export function LeftTwoColumn({ children, breakpoint = "xl", className }: { children: React.ReactNode, breakpoint?: keyof typeof screenSizes, className?: string }) {
    const isDesktop = useMediaQuery(breakpoint);
    return <div className={cn(className, isDesktop ? cn("@[1000px]:max-w-[500px] @[1000px]:w-full @[1000px]:col-span-4 @[1000px]:row-start-auto col-span-12") : cn("col-span-12 @[1000px]:w-full  @[1000px]:max-w-[500px]"))}>
            {children}
        </div>
}

export function RightTwoColumn({ children, breakpoint = "xl", className }: { children: React.ReactNode, breakpoint?: keyof typeof screenSizes, className?: string }) {
    const isDesktop = useMediaQuery(breakpoint);
    return <div className={cn(className, isDesktop ? "@[1000px]:w-full col-span-12" : "col-span-12 @[1000px]:w-full")}>
            {children}
        </div>
}

export function TightTwoColumnLayout({ children, className }: { children: React.ReactNode, className?: string, breakpoint?: keyof typeof screenSizes }) {

    return <div className="@container h-full">
        <div className={cn("grid grid-cols-12 w-full gap-x-8 gap-y-4 @[1000px]:flex h-full", className)}>
            {children}
        </div>
    </div>
}
export function TightLeftColumn({ children, className, breakpoint = "xl" }: { children: React.ReactNode, className?: string, breakpoint?: keyof typeof screenSizes }) {
    const isDesktop = useMediaQuery(breakpoint);

    return <div className={cn("h-full", isDesktop ? "@[1000px]:w-full col-span-12" : "col-span-12 @[1000px]:w-full", className)}>
        {children}
    </div>
}
export function TightRightColumn({ children, className, breakpoint = "xl" }: { children: React.ReactNode, className?: string, breakpoint?: keyof typeof screenSizes }) {
    const isDesktop = useMediaQuery(breakpoint);

    return <div className={cn("h-full", isDesktop ? "@[1000px]:max-w-[300px] @[1000px]:w-full @[1000px]:col-span-4 @[1000px]:row-start-auto col-span-12" : "col-span-12 @[1000px]:w-full", className)}>
        {children}
    </div>
}



export function WorkspaceLayout({ children }: { children: React.ReactNode }) {
    /*A layout with a topbar and a content*/
    return <div className="flex flex-col h-full">
        {children}
    </div>
}

export function WorkspaceTopBar({ children }: { children: React.ReactNode }) {
    return <div className="flex items-center gap-x-3 w-full justify-between border-b px-4 py-2">
        {children}
    </div>
}
// WorkspaceTopBarLeft
export function WorkspaceTopBarLeft({ children, className }: { children: React.ReactNode, className?: string }) {
    return <div className={cn("flex items-center gap-x-3", className)}>
        {children}
    </div>
}
// WorkspaceTopBarRight
export function WorkspaceTopBarRight({ children }: { children: React.ReactNode }) {
    return <div className="flex items-center gap-x-3">
        {children}
    </div>
}

export function InvTwoColumnLayout({ left, right, className, breakpoint = "xl", collapseBefore, loading }: { left: React.ReactNode, right: React.ReactNode, className?: string, breakpoint?: keyof typeof screenSizes, collapseBefore?: boolean, loading?: boolean }) {
    const isDesktop = useMediaQuery(breakpoint);

    return <div>
        <div className={cn("grid grid-cols-12 w-full gap-x-8 gap-y-4 @[1000px]:flex", className)}>
            <div className={cn("", isDesktop ? cn("@[1000px]:max-w-[500px] @[1000px]:w-full @[1000px]:col-span-4 @[1000px]:row-start-auto col-span-12", collapseBefore && "row-start-1") : cn("col-span-12 @[1000px]:w-full @[1000px]:max-w-[500px]", collapseBefore && "row-start-1"))}>
                {!loading ? left : <Skeleton className="h-44" />}
            </div>
            <div className={cn("", isDesktop ? "@[1000px]:w-full col-span-12" : "col-span-12 @[1000px]:w-full")}>
                {!loading ? right : <Skeleton className="h-32" />}
            </div>
        </div>
    </div>
}

export function Page({ children, title, description, loading }: { children?: React.ReactNode, title?: string, description?: string, loading?: boolean }) {

    return <>
        <div className="px-5 mb-5">
            <PageTitle className="" title={title} description={description} loading={loading} />
            {children}
        </div>
    </>
}


export const Layouts = {
    Full: FullLayout,
    Dashboard: DashboardLayout,
    TwoColumns: TwoColumnLayout,
    TightTwoColumns: TightTwoColumnLayout,
    TightLeftColumn: TightLeftColumn,
    TightRightColumn: TightRightColumn,
    LeftTwoColumns: LeftTwoColumn,
    RightTwoColumns: RightTwoColumn,
    InvTwoColumns: InvTwoColumnLayout,
    Page
}